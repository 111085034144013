import FileSaver from "file-saver";
import XLSX from "xlsx";

var ExportExcel = function () {
  this.exportExcel=function(tableId, excelName) {
    // 生成Excel工作簿对象
    console.log("aaaaaaaaaaaa",tableId, excelName)
    var wb = XLSX.utils.table_to_book(document.querySelector("#" + tableId));
    // 获取二进制字符串作为输出
    var wbout = XLSX.write(wb, {
      bookType: "xlsx",
      book: true,
      type: "array",
    });
    try {
      FileSaver.saveAs(
        // Blob: 对象表示一个不可变 原始数据的类文件对象,不一定是JS原生格式的数据。
        // File: 基于Blob，继承了blob的功能并将其扩展使其支持用户系统上的文件。
        new Blob([wbout], { type: "appliction/octet-stream" }),
        // 设置导出的文件名称可随意
        excelName + ".xlsx"
      );
    } catch (e) {
      if (typeof console != "undefined") console.log(e, wbout);
    }
    // 返回一个新创建的Blob对象，其内容由参数中给定的数组串联组成。
    return wbout;
  };

  this.openDownloadXLSXDialog=function(url, saveName){

    if(typeof url == 'object' && url instanceof Blob){
      url = URL.createObjectURL(url); // 创建blob地址
  }
  
  var aLink = document.createElement('a');
  aLink.href = url;
  aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
  var event;
  if(window.MouseEvent) event = new MouseEvent('click');
  else{
      event = document.createEvent('MouseEvents');
      event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
  }
  aLink.dispatchEvent(event);
  };
  this.sheet2blob=function(sheet,sheetName){
    sheetName = sheetName || 'sheet1';
    var workbook = {
        SheetNames: [sheetName],
        Sheets: {}
    };
    workbook.Sheets[sheetName] = sheet;
    // 生成excel的配置项
    var wopts = {
        bookType: 'xlsx', // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: 'binary'
    };
    var wbout = XLSX.write(workbook, wopts);
    var blob = new Blob([s2ab(wbout)], {type:"application/octet-stream"});
    // 字符串转ArrayBuffer
    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }
    return blob;
  };

  this.exportExcelByDoubleDimensArray=function(workSheetData, fileName = "已支付订单列表.csv") {
    // const ws = XLSX.utils.aoa_to_sheet(workSheetData);
    // const workSheetName = "MySheet";
    // const workbook = XLSX.utils.book_new();

    // XLSX.utils.book_append_sheet(workbook, ws, workSheetName);
    // console.log("exportExcelByDoubleDimensArray")
    // return XLSX.writeFile(workbook, fileName, { type: "binary" });
    var sheet = XLSX.utils.aoa_to_sheet(workSheetData);
    var blob = this.sheet2blob(sheet, 'sheet1');
    this.openDownloadXLSXDialog(blob, fileName);

  };

  this.analyseExcelToJson=function(file) {
    return new Promise((resolve, reject) => {
      if (file instanceof File) {
        const reader = new FileReader();

        reader.onloadend = (progressEvent) => {
          const arrayBuffer = reader.result;

          const options = { type: "array" };
          const workbook = XLSX.read(arrayBuffer, options);

          const sheetNames = workbook.SheetNames;
          const result = sheetNames.map(
            //(sheetName) => workbook.Sheets[sheetName]
            (sheetName) =>
              XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
                range: 1,
                defval: "",
              })
          );
          resolve(result);
        };
        reader.readAsArrayBuffer(file);
      } else {
        reject();
      }
    });
  };
}
export { ExportExcel };

